export default {
	navPrev:"shift + 9",
	navNext:9,
	navUp:38,
	navDown:40,
	scrollPageUp:33,
	scrollPageDown:34,
	scrollToStart:36,
	scrollToEnd:35,
	undo:"ctrl + 90",
	redo:"ctrl + 89",
	copyToClipboard:"ctrl + 67",
};